@import "definitions";

.logo-administration__bg--transparent {
    background-image: url("../images/administartion-logo.svg");
}

:focus {
    outline: none;
}

.form-row.form-row--input {
    .form-row__label {
        width: 190px;
        &--multiline {
            line-height: 1.2em;
        }
    }
}

.lg-multi-filter {
    &--form {
        &--invalid {
            .lg-multi-filter__frame,
            .lg-multi-filter__frame.lg-multi-filter__frame--selected {
                border-color: $error-color;
            }
        }
        &--disabled-with-dialog {
            .lg-multi-filter__frame__count {
                color: $base-60 !important;
                cursor: pointer;

                &:hover {
                    color: $base-70;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.warning-box {
    background-color: rgba($warning-color, 0.1);
    border-radius: $border-radius;
    padding: $spacing2;

    h4 {
        color: $gray-color-80;
    }
}

.lg-icon--gray {
    color: $gray-color-25 !important;
}

.lg-icon--success {
    color: $ok-color;
}

.lg-icon--primary {
    color: $base-60 !important;
}

.lg-dropdown.lg-dropdown--missing.lg-dropdown--disabled {
    background-color: $gray-color-2;
    border: 1px solid $gray-color-10;
}

.lg-dropdown.lg-dropdown--disabled {
    background-color: $gray-color-2;
    border: 1px solid $gray-color-10;
    color: $gray-color-80;
}

.text-highlight {
    background-color: rgba($base-60, 0.2);
}

.lg-block--table {
    border: 1px solid $gray-color-20;
    border-radius: $border-radius;
    margin-top: -$spacing2;
}

.table__row--error {
    color: $error-color;
}
